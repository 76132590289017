export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Event Journey',
    route: 'second-page',
    icon: 'FileIcon',
  },
  {
    title: 'Purchase journey',
    route: 'purchase-journey',
    icon: 'FileIcon',
  },
]
