<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <CustSwitch
        :items="getCustIdsWithName"
        :selected-id="getCurrentCustId"
        @selected="onCustIdSwitch"
      />
      <GoogleProfile
        v-if="googleUserProfile"
        :basic-profile="googleUserProfile"
      />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { createNamespacedHelpers } from 'vuex'
import * as MutationTypes from '@/store/global-mutation-types'
import CustSwitch from './CustSwitch.vue'
import GoogleProfile from './GoogleProfile.vue'

const { mapMutations: mapUserMutation, mapGetters: mapUserGetters } = createNamespacedHelpers('user')

export default {
  components: {
    BLink,
    BNavbarNav,
    GoogleProfile,
    CustSwitch,
    // Navbar Components
    // DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapUserGetters(['getCurrentCustId', 'getCustIdsWithName', 'googleUserProfile']),
  },
  methods: {
    onCustIdSwitch(id) {
      this.switchCustId(id)
      window.location.href = '/'
    },
    ...mapUserMutation({ switchCustId: MutationTypes.SET_CURRENT_CUST_ID }),
  },
}
</script>
