<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <span class="ml-50 text-body">{{ selectedItem.name }}</span>
    </template>
    <b-dropdown-item
      v-for="item in items"
      :key="item.id"
      @click="setSelected(item)"
    >
      <span class="ml-50">{{ item.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: String,
      default: () => 'demo',
    },
  },
  setup() {

  },
  computed: {
    selectedItem() {
      return this.items.find(item => item.id === this.selectedId)
    },
  },
  methods: {
    setSelected(item) {
      // this.selectedId = item.id
      this.$emit('selected', item.id)
    },
  },
}
</script>

<style>

</style>
