<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ fullName }}
        </p>
        <!-- <span class="user-status">Admin</span> -->
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        :src="imageUrl"
        class="badge-minimal"
        badge-variant="success"
      />
    </template>

    <b-dropdown-item link-class="d-flex align-items-center">
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  props: {
    basicProfile: {
      type: Object,
      required: true,
    },
  },
  setup() {

  },
  computed: {
    fullName() {
      if (this.basicProfile) {
        return this.basicProfile.getName()
      }
      return ''
    },
    imageUrl() {
      if (this.basicProfile) {
        return this.basicProfile.getImageUrl()
      }
      return ''
    },
  },
})
</script>
